import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { authLink, refreshTokenLink } from 'utils/auth';
import { getGlobalConfig } from 'utils/config';

const platformAPILink = createHttpLink({
  uri: getGlobalConfig().GRAPHQL_URL,
});

const bffLink = createHttpLink({
  uri: getGlobalConfig().BFF_URL,
  credentials: 'include',
});

const link = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
});

export const BFF_CLIENT_NAME = 'bff';

export const client = new ApolloClient({
  name: 'Everest',
  version: window.buildVersion,
  link: link.split(
    (operation) => operation.getContext().clientName === BFF_CLIENT_NAME,
    from([authLink, refreshTokenLink, bffLink]),
    from([authLink, refreshTokenLink, platformAPILink]),
  ),
  credentials: 'include',
  cache: new InMemoryCache({
    typePolicies: {
      Transaction: {
        fields: {
          dates: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      SummaryField: {
        keyFields: ['id', 'stepId'],
      },
    },
  }),
});
