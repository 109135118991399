import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Field, FieldProps, useFormikContext } from 'formik';
import HelloSign from 'hellosign-embedded';
import { Flex, Heading, InputGroup, Modal, ModalContent, useDisclosure } from '@endpoint/blockparty';
import { FieldOptions } from '@endpoint/endpoint-bff-graphql-schema';
import { getConfig, getGlobalConfig } from 'utils/config';
import { useSignatureUrl } from 'hooks/useSignatureUrl';
import { ErrorLoadingStatesWidget } from 'routes/TransactionDetail/Todo/TodoStep/TodoStepComponent/ErrorLoadingStatesWidget';
import { useTodoStepContext } from 'routes/TransactionDetail/Todo/TodoStep';

import { updateSigningErrorStatus } from './helpers';
import {
  getHelloSignStatuses,
  HelloSignEvents,
  HelloSignFieldValue,
  HelloSignStatus,
  HelloSignValue,
  HELLO_SIGN_CONTAINER_ID,
  SignatureUrlRequestErrorMessage,
} from './const';

interface EsignProps {
  helloSignAltClient?: HelloSign;
  field: FieldOptions;
  onPrevious: () => void;
}

export const EsignWidget: FC<EsignProps> = ({ field, helloSignAltClient, onPrevious }: EsignProps) => {
  const { todoId } = useParams();
  const { setFieldValue, values } = useFormikContext<GenericObject>();
  const { isEsignDocumentSigned, setIsEsignDocumentSigned } = useTodoStepContext();
  const { HELLOSIGN_CLIENT_ID } = getConfig();
  const { ENVIRONMENT } = getGlobalConfig();
  const { getSignUrl, signatureUrl, isLoading, esignError } = useSignatureUrl(todoId!);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    todoAssignment: {
      navigationPanel: [prevButton, nextButton],
    },
  } = useTodoStepContext();
  const statuses = getHelloSignStatuses(nextButton.text);
  const [signingStatus, setSigningStatus] = useState<HelloSignStatus>(statuses.NOT_LOADED);
  const isDocumentHasBeenSignedError = esignError?.message.match(SignatureUrlRequestErrorMessage.ALREADY_SIGNED);
  const hasSignedValue = values[field.id];
  const openHelloSignModal = useCallback(() => {
    onOpen();
  }, [onOpen]);
  const helloSignClient =
    helloSignAltClient ||
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMemo(
      () =>
        new HelloSign({
          clientId: HELLOSIGN_CLIENT_ID,
        }),
      [HELLOSIGN_CLIENT_ID],
    );

  useEffect(() => {
    if (esignError) {
      onClose();
      updateSigningErrorStatus({
        setSigningStatus,
        setIsEsignDocumentSigned,
        errorMessage: esignError.message,
        setFieldValue,
        fieldId: field.id,
        statuses,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esignError, signingStatus]); // Need signingStatus here

  useEffect(() => {
    if (!signatureUrl && !isEsignDocumentSigned) {
      getSignUrl();
    }
  }, [isEsignDocumentSigned, signatureUrl, getSignUrl]);

  useEffect(() => {
    if (!isLoading && !hasSignedValue) {
      openHelloSignModal();
    }
  }, [openHelloSignModal, hasSignedValue, isLoading]);

  useEffect(() => {
    if (!esignError && signatureUrl && isOpen) {
      const settings = {
        container: document.getElementById(HELLO_SIGN_CONTAINER_ID)!,
        skipDomainVerification: ENVIRONMENT !== 'production',
      };

      helloSignClient.open(signatureUrl, settings);

      helloSignClient.on(HelloSignEvents.SIGN, async () => {
        setFieldValue(field.id, HelloSignValue.SIGN);
        setFieldValue(HelloSignFieldValue.SIGNED, true);
        setSigningStatus(statuses.SIGNED);
        setIsEsignDocumentSigned(true);
        helloSignClient.close();
        onClose();
      });

      helloSignClient.on(HelloSignEvents.DECLINE, () => {
        setFieldValue(field.id, HelloSignValue.DECLINE);
        setFieldValue(HelloSignFieldValue.DECLINED, true);
        setSigningStatus(statuses.DECLINED);
      });

      helloSignClient.on(HelloSignEvents.CANCEL, () => {
        onClose();
        onPrevious();
      });

      helloSignClient.on(HelloSignEvents.ERROR, () => {
        setSigningStatus(statuses.ERROR);
      });
    }

    return () => {
      helloSignClient.close();

      if (!hasSignedValue) {
        setSigningStatus(statuses.NOT_LOADED);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helloSignClient, signatureUrl, isOpen, onClose, hasSignedValue]);

  if (isLoading || (esignError && !isDocumentHasBeenSignedError)) {
    return <ErrorLoadingStatesWidget error={esignError} loading={isLoading} />;
  }

  return (
    <Field name={field.id}>
      {({ field: formikField }: FieldProps) => (
        <InputGroup groupId={field.id} {...formikField}>
          <Flex>
            <HelloSignModal isOpen={isOpen} />
            {signingStatus !== statuses.NOT_LOADED && <Heading size="fontSize30">{signingStatus}</Heading>}
          </Flex>
        </InputGroup>
      )}
    </Field>
  );
};

interface HelloSignModalProps {
  isOpen: boolean;
}

export const HelloSignModal: FC<HelloSignModalProps> = ({ isOpen }: HelloSignModalProps) => {
  return useMemo(() => {
    return (
      <Modal id="esign-modal" isOpen={isOpen} onClose={() => {}}>
        <ModalContent height="80vh" id={HELLO_SIGN_CONTAINER_ID} maxWidth={850} />
      </Modal>
    );
  }, [isOpen]);
};
