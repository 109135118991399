import { escapeRegExp } from 'lodash';
import * as Sentry from '@sentry/react';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { IdentityProviderConfig } from '../config';

export type IdpEmailMatcher = {
  cognitoIdp: string;
  emailMatchingRegex: RegExp;
};

export const PREVENT_EMAIL_MATCH = 'INVALID CONFIGURATION';

export const IS_SSO_KEY = 'isSSO';

/**
 * @returns email matching regex for each configured SSO identity provider
 */
export const getSsoEmailMatchingConfigurations = (ssoIdentityProvider: IdentityProviderConfig[]): IdpEmailMatcher[] => {
  if (ssoIdentityProvider) {
    return ssoIdentityProvider.map((idpConfig: IdentityProviderConfig) => getIdpEmailMatcher(idpConfig));
  }

  return [];
};

/**
 * @returns boolean indicating whether the provided email matches an enabled sso domain
 * Currently only Avenue8 emails can have sso disabled using a feature flag
 */
export const isEnabledSsoEmail = (
  ssoIdentityProvider: IdentityProviderConfig[],
  nonSsoEmails: string[] | undefined,
  email: string,
  isAvenue8SsoEnabled?: boolean,
  isAzureSsoEnabled?: boolean,
): boolean => {
  if (!email || isNonSsoEmail(nonSsoEmails, email)) {
    return false;
  }

  const ssoEmailMatchers = getSsoEmailMatchingConfigurations(ssoIdentityProvider);
  const matchingIdp = ssoEmailMatchers?.find((idp) => !!email.match(idp.emailMatchingRegex))?.cognitoIdp;

  if (matchingIdp === 'avenue8-external-idp') {
    return Boolean(isAvenue8SsoEnabled);
  }

  if (matchingIdp === 'endpoint-azure-saml-idp') {
    return Boolean(isAzureSsoEnabled);
  }

  return !!matchingIdp;
};

export const isNonSsoEmail = (nonSsoEmails: string[] | undefined, email: string): boolean => {
  const nonSsoEmailsInLowerCase = (nonSsoEmails ?? []).map(
    // do not change case of regex
    (nonSsoEmail) => (nonSsoEmail.includes('^.') ? nonSsoEmail : nonSsoEmail.toLowerCase()),
  );

  return nonSsoEmailsInLowerCase.some((nonSsoEmail) => {
    return email.toLowerCase() === nonSsoEmail || email.toLowerCase().match(nonSsoEmail);
  });
};

const getIdpEmailMatcher = (idpConfig: IdentityProviderConfig): IdpEmailMatcher => {
  if (!idpConfig?.emailDomain?.match(/..\.../)) {
    reportSsoDomainError(idpConfig);

    return buildInvalidMatcher(idpConfig);
  }

  return {
    cognitoIdp: idpConfig.cognitoIdentityProvider,
    emailMatchingRegex: buildSsoEmailMatchRegex(idpConfig.emailDomain, idpConfig.matchEmailSubstring),
  };
};

const buildSsoEmailMatchRegex = (emailDomain: string, matchEmailSubstring?: string) => {
  const domainRegex = escapeRegExp(emailDomain);
  const plusSubaddressRegex = matchEmailSubstring ? escapeRegExp(`+${matchEmailSubstring}`) : '';
  const emailMatchRegex = `${plusSubaddressRegex}.*@${domainRegex}\\s*$`;

  return new RegExp(emailMatchRegex, 'i');
};

const buildInvalidMatcher = (idpConfig: IdentityProviderConfig): IdpEmailMatcher => {
  return {
    cognitoIdp: idpConfig.cognitoIdentityProvider,
    emailMatchingRegex: new RegExp(PREVENT_EMAIL_MATCH),
  };
};

const reportSsoDomainError = (idpConfig: IdentityProviderConfig) => {
  Sentry.withScope((scope) => {
    scope.setExtra('ssoConfig', idpConfig);
    Sentry.captureEvent({
      level: Sentry.Severity.Error,
      message: `SSO configuration has invalid email domain. SSO authentication will not work for provider ${idpConfig.cognitoIdentityProvider}`,
    });
  });
};

export const isSsoUser = (user: CognitoUser | any) => {
  return user?.attributes && user?.attributes['custom:ExternalUserId'];
};

export const getIdentityProviderConfigByProviderName = (
  ssoIdentityProvider: IdentityProviderConfig[],
  providerName: string,
): IdentityProviderConfig | undefined => {
  return ssoIdentityProvider.find((idpConfig) => idpConfig.cognitoIdentityProvider === providerName);
};
