import { getGlobalConfig } from 'utils/config';
import { capitalize } from 'lodash';
import { isWebView } from 'utils/webview';
import { createAnalyticsTracker } from 'utils/analytics/trackerFactory';
import { User } from 'utils/context';
import {
  CreateUnityContactInput,
  UpdateUnityContactInput,
  UpdateContactInput,
} from '@endpoint/endpoint-bff-graphql-schema';

const segmentKey = getGlobalConfig().SEGMENT_WRITE_KEY;
const analyticsTracker = createAnalyticsTracker(segmentKey);

export const trackAction = analyticsTracker.trackAction;
export const trackPage = analyticsTracker.trackPage;
export const trackIdentity = analyticsTracker.trackIdentity;
export const trackIdentityPreCreation = analyticsTracker.trackIdentityPreCreation;

/**
rolesStr is used to send Onboarding Segment events to #ep-app-onboarding-events
via the Segment Slack Integration (see Destination Filters)
*/
export interface ExtendedUser extends User {
  roles?: string[];
  rolesStr?: string;
  offices?: (string | undefined | null)[];
}

export const formatAnalyticsIdentity = (data: ExtendedUser) => {
  return {
    userId: data.id,
    firstName: data?.firstName && capitalize(data.firstName),
    middleName: data?.middleName && capitalize(data.middleName),
    lastName: data?.lastName && capitalize(data.lastName),
    suffix: data?.suffix && capitalize(data?.suffix),
    email: data?.email,
    phone: data?.phone,
    realEstateAgentLicenseNumber: data?.realEstateAgentLicenseNumber,
    onboarded: data?.isOnboarded,
    beta: data?.betaGroup,
    roles: data?.roles,
    rolesStr: data?.rolesStr,
    offices: data?.offices,
    webview: isWebView(),
  };
};

export const formatAnalyticsProfileIdentity = (data: UpdateContactInput) => {
  return {
    firstName: data?.firstName && capitalize(data.firstName),
    lastName: data?.lastName && capitalize(data.lastName),
    middleName: data?.middleName && capitalize(data.middleName),
    suffix: data?.suffix && capitalize(data?.suffix),
    licenseNumber: data?.licenseNumber,
    stateOfOperation: data?.stateOfOperation,
    entityName: data?.entityName,
  };
};

export const formatAnalyticsUnityProfileIdentity = (
  data: Partial<CreateUnityContactInput | UpdateUnityContactInput>,
) => {
  return {
    firstName: data?.firstName && capitalize(data.firstName),
    lastName: data?.lastName && capitalize(data.lastName),
    middleName: data?.middleName && capitalize(data.middleName),
    suffix: data?.suffix && capitalize(data?.suffix),
    licenseNumber: data?.licenses?.[0]?.licenseNumber,
    stateOfOperation: data?.licenses?.[0]?.stateOfOperation,
    entityName: data?.entityName,
  };
};
