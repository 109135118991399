import { getFlagValue } from 'utils/getFlagValue';
import { DEFAULT_ORGANIZATION_ID } from 'utils/config';
import { PublicOrganization } from '@endpoint/endpoint-bff-graphql-schema';

import { getConfig, getGlobalConfig } from '../config';

const { ORGANIZATION_ID } = getConfig();
const { BFF_URL } = getGlobalConfig();

const organization: PublicOrganization = {
  id: DEFAULT_ORGANIZATION_ID,
  shortName: '',
  brandName: '',
  epAppConfigs: {
    BFF_URL: '',
    HELP_URL: '',
    ENVIRONMENT: '',
    GRAPHQL_URL: '',
    PRIVACY_URL: '',
    COMPANY_EMAIL: '',
    CONTACT_US_URL: '',
    NON_SSO_EMAILS: [],
    ZENDESK_APP_ID: '',
    SSO_CALLBACK_URL: '',
    SEGMENT_WRITE_KEY: '',
    DATA_PRIVACY_EMAIL: '',
    GOOGLE_TAG_MANAGER: {
      auth: '',
      gtmId: '',
      preview: '',
    },
    THEME_URL: '',
    COMPANY_PHONE_NUMBER: {
      generalInquiries: '',
      technicalSupport: '',
    },
    MANIFEST_URL: '',
    LAUNCH_DARKLY_CLIENT_ID: '',
    AWS_COGNITO_SSO_IDENTITY_PROVIDERS: [],
  },
};

let fetchPromise: undefined | Promise<void>;
let didFetch = false;

const DEFAULT_DOMAIN = process.env?.REACT_APP_DEFAULT_DOMAIN;

export const queryLegacy = `
  query GetOrganizationByDomainShortname($input: GetOrganizationByDomainShortnameInput!) {
    getOrganizationByDomainShortname(input: $input) {
      id
      brandName
      shortName      
    }
  }
`;

export const query = `
  query GetOrganizationByDomainShortname($input: GetOrganizationByDomainShortnameInput!) {
    getOrganizationByDomainShortname(input: $input) {
      id
      brandName
      shortName
      epAppConfigs {
        BFF_URL
        HELP_URL
        ENVIRONMENT
        GRAPHQL_URL
        PRIVACY_URL
        COMPANY_EMAIL
        CONTACT_US_URL
        NON_SSO_EMAILS
        ZENDESK_APP_ID
        SSO_CALLBACK_URL
        SEGMENT_WRITE_KEY
        DATA_PRIVACY_EMAIL
        GOOGLE_TAG_MANAGER {
          auth
          gtmId
          preview
        }
        THEME_URL
        COMPANY_PHONE_NUMBER {
          generalInquiries
          technicalSupport
        }
        MANIFEST_URL
        LAUNCH_DARKLY_CLIENT_ID
        AWS_COGNITO_SSO_IDENTITY_PROVIDERS{
          emailDomain
          partnerName
          matchEmailSubstring
          cognitoIdentityProvider
        }
      }
    }
  }
`;

export function getDomain(isAppConfigsFromOrgService: boolean = false) {
  const hostParts = window.location.hostname.split('.');
  const len = hostParts.length;
  const domain = `${hostParts[len - 2] ? `${hostParts[len - 2]}.` : ''}${hostParts[len - 1]}`;

  return isAppConfigsFromOrgService && domain === 'localhost' && DEFAULT_DOMAIN ? DEFAULT_DOMAIN : domain;
}

export const getVariables = (isAppConfigsFromOrgService: boolean = false) => {
  return {
    input: {
      domain: getDomain(isAppConfigsFromOrgService),
    },
  };
};

async function fetchOrganizationData() {
  let isAppConfigsFromOrgService = false;

  try {
    isAppConfigsFromOrgService = await getFlagValue('isAppConfigsFromOrgService', false, {
      kind: 'boolean',
      key: 'isAppConfigsFromOrgService',
    });
  } catch (error) {
    console.error('Error getting flag value for isAppConfigsFromOrgService:', error);
  }

  const variables = getVariables(isAppConfigsFromOrgService);
  const queryText = isAppConfigsFromOrgService ? query : queryLegacy;

  try {
    const response = await fetch(BFF_URL, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: queryText,
        variables,
      }),
    });
    const responseJson = await response.json();
    const organizationData = responseJson?.data?.getOrganizationByDomainShortname;

    organization.shortName = organizationData?.shortName || '';
    organization.brandName = organizationData?.brandName || '';
    organization.epAppConfigs = organizationData?.epAppConfigs || {};

    if (!Number.isNaN(parseFloat(organizationData?.id))) {
      organization.id = organizationData?.id;
    }
  } catch (_err) {
    organization.id = isAppConfigsFromOrgService ? DEFAULT_ORGANIZATION_ID : ORGANIZATION_ID;
  } finally {
    didFetch = true;
  }
}

export async function getOrganizationId() {
  if (didFetch) {
    return organization.id;
  }

  if (!fetchPromise) {
    fetchPromise = fetchOrganizationData();
  }

  await fetchPromise;

  return organization.id;
}

export async function getOrganizationDetails() {
  if (didFetch) {
    return organization;
  }

  if (!fetchPromise) {
    fetchPromise = fetchOrganizationData();
  }

  await fetchPromise;

  return organization;
}
